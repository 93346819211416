.menuItem {
  font-weight: 400 !important;
  font-size: 15px !important;
  line-height: 23px !important;
  position: relative;
}

.verticalMenuBlock {
  top: auto;
  left: 260px;
  width: 20rem;
  position: absolute;
  max-height: 350px;
  overflow-y: auto;
}

.verticalMenuBlockWrapper {
  padding: 20px 10px !important;
}

.verticalMenuItem:hover .verticalMenuBlock {
  display: block;
  visibility: visible;
  margin-top: -35px;
}

.lastItem {
  color: var(--secondary-500) !important;
}
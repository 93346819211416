.locations-menu:hover .locations-sub-menu {
  display: block;
  visibility: visible;
}

.locations-sub-menu .u-header__mega-menu-wrapper {
  padding: 0;
}

.locations-sub-menu
  .u-header__mega-menu-wrapper
  ul.u-header__sub-menu-nav-group
  li {
  padding: 10px 15px;
}

.locations-sub-menu
  .u-header__mega-menu-wrapper
  ul.u-header__sub-menu-nav-group
  li
  a {
  font-weight: 400;
  font-size: 15px;
  line-height: 32px;
  justify-content: space-between;
  padding: 0;
}

.locations-sub-menu
  .u-header__mega-menu-wrapper
  ul.u-header__sub-menu-nav-group
  li:hover {
  background: var(--secondary-100);
}

.locations-sub-menu
  .u-header__mega-menu-wrapper
  ul.u-header__sub-menu-nav-group
  li.selected {
  background: var(--secondary-50);
}

.locations-sub-menu
  .u-header__mega-menu-wrapper
  ul.u-header__sub-menu-nav-group
  li:hover
  a,
.locations-sub-menu
  .u-header__mega-menu-wrapper
  ul.u-header__sub-menu-nav-group
  li.selected
  a {
  font-weight: 700;
  color: var(--text-grey-400);
}

.locations-sub-menu
  .u-header__mega-menu-wrapper
  ul.u-header__sub-menu-nav-group
  li
  a:hover {
  text-decoration: none;
  color: var(--text-grey-400);
}

.locations-sub-menu
  .u-header__mega-menu-wrapper
  ul.u-header__sub-menu-nav-group
  li
  a.link-toggle::after {
  content: "e";
  font: normal normal normal 14px/1 font-electro;
  font-size: inherit;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-size: 1.00012rem;
  color: var(--text-grey-400);
  margin-top: 0.25025rem;
}

.locations-sub-menu
  .u-header__mega-menu-wrapper
  ul.u-header__sub-menu-nav-group.states,
.locations-sub-menu
  .u-header__mega-menu-wrapper
  ul.u-header__sub-menu-nav-group.cities {
  max-height: 320px;
  overflow-y: auto;
}

.locations-sub-menu
  .u-header__mega-menu-wrapper
  ul.u-header__sub-menu-nav-group.states
  li,
.locations-sub-menu
  .u-header__mega-menu-wrapper
  ul.u-header__sub-menu-nav-group.cities
  li {
  padding: 4px 15px;
}

.locations-sub-menu
  .u-header__mega-menu-wrapper
  ul.u-header__sub-menu-nav-group.states
  li:hover,
.locations-sub-menu
  .u-header__mega-menu-wrapper
  ul.u-header__sub-menu-nav-group.states
  li.selected {
  background: var(--secondary-100);
}

.locations-sub-menu .bdr-left {
  border-left: 2px solid var(--purple-50);
}
.step {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #afb0b5;
  align-self: center;
}

.step span {
  color: var(--purple-500);
}

@media (max-width: 460px) {
  .step {
    font-size: 16px;
  }
}

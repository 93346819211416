.modal {
  position: fixed;
  top: 30vh;
  left: 10%;
  width: 80%;
  z-index: 10000;
  overflow: hidden;
}

.header {
  padding: 0.7rem 15px;
}

.content {
  padding: 1rem;
}

.header h6 {
  text-align: center;
  margin-top: 10px;
}

.content p {
  text-align: center;
  font-size: 20px;
  line-height: 26px;
  margin: 0;
}

.actions {
  padding: 1rem;
  display: flex;
  justify-content: center;
}

.actions button {
  margin: 10px;
}

@media (max-width: 550px) {
  .actions {
    flex-direction: column-reverse;
  }

  .actions button {
    margin: 0px;
    margin-top: 10px;
  }
}

@media (min-width: 768px) {
  .modal {
    left: calc(50% - 15rem);
    width: 30rem;
  }
}

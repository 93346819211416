.view {
  padding: 5px 5px;
  border-bottom: 1px solid var(--purple-500);
}

.label {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: var(--text-grey-300);
  margin-bottom: 2px;
  word-wrap: break-word;
}

.value {
  font-size: 14px;
  font-weight: 700;
  color: var(--purple-500);
  margin-top: 7px;
  min-height: 21px;
  word-wrap: break-word;
}

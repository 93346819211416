.span {
  display: flex;
  cursor: pointer;
  align-items: center;
  column-gap: 15px;
}

.span .icon img {
  height: 18px;
  width: 18px;
}

.span .text {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--text-grey-500);
}

.span .text .name {
  color: var(--secondary-500);
  font-weight: 700;
}

.span .caret {
}

.dropdown {
  position: relative;
}

.dropdown .subMenu {
  position: absolute;
  top: 30px;
  left: 20px;
  display: none;
  background: #fff;
  z-index: 999;
  list-style: none;
  box-shadow: 0 2px 5px rgb(0 0 0 / 28%);
  width: 170px;
}

.dropdown .subMenu li:first-child {
  padding-top: 10px;
}
.dropdown .subMenu li:last-child {
  padding-bottom: 10px;
}

.dropdown .subMenu li {
  padding: 5px 20px;
}

.dropdown .subMenu li hr {
  margin: 0;
}

.dropdown .subMenu li.noPadding {
  padding: 5px 0;
}

.dropdown .subMenu li a {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--text-grey-500);
}

.dropdown .subMenu li a:hover,
.dropdown .subMenu li a:active {
  font-weight: 700;
}

.dropdown .subMenu.show {
  display: block;
}

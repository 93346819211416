.footerPara {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: var(--text-grey-500);
}

.copyRightsBar {
  font-weight: 400;
  font-size: 16px;
  line-height: 36px;
  color: var(--text-grey-500);
}

.footerMainLinks li a {
  font-weight: 400;
  font-size: 16px !important;
  line-height: 24px;
  color: var(--text-grey-500);
}

.footerHeading2 {
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: var(--purple-500);
}

@media (max-width: 767px) {
}

@media (max-width: 575px) {
  .footerHeading2 {
    font-size: 14px;
  }

  .footerMainLinks li a {
    font-size: 13px !important;
    line-height: 18px;
  }

  .copyRightsBar {
    font-size: 13px;
    text-align: center;
  }
}

.imgContainer {
  width: 200px;
  height: 140px;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  border-radius: 12px;
  margin: 0px 15px;
}

.searchBtn{
  margin-right: 8px;
}

@media (max-width: 991px) {
  .topRow {
    flex-direction: column;
  }
  .searchBtn{
    margin-right: 0px;
  }
}

@media (max-width: 767px) {
  .searchBtn{
    margin-right: 0px;
  }
}

.dateCalender {
  background: url("../../assets/images/datepicker-calender.svg") no-repeat;
  background-size: 20px;
  background-position: 98%
}


.zScrollbar
{
	max-height: 200px;
	overflow-y: scroll;
}

 /* Firefox */
 .zScrollbar * {
  scrollbar-width: auto;
  scrollbar-color: #D9D9D9 #ffffff;
}

/* Chrome, Edge, and Safari */
.zScrollbar::-webkit-scrollbar {
  width: 16px;
}

.zScrollbar::-webkit-scrollbar-track {
  background: #ffffff;
}

.zScrollbar::-webkit-scrollbar-thumb {
  background-color: #D9D9D9;
  border-radius: 5px;
  border: 3px solid #ffffff;
}
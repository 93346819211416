.accordion {
  list-style: none;
  padding: 0;
}

.accordion .accordion_item:first-child {
  border-top: none;
}

.accordion .accordion_item {
  border-bottom: 1px solid #dadbde;
}

.accordion .accordion_item:last-child {
  border-bottom: 1px solid #dadbde;
}

.accordion .button {
  font-size: 18px;
  line-height: 20px;
  background-color: #ffffff;
  text-align: left;
  font-weight: 400;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 15px 8px;
  cursor: pointer;
  border: none;
}

.accordion .control {
  font-size: 20px;
}

.accordion .innerpanel {
  padding: 0;
}

.accordion .innerpanel ul.innerpanel-links {
  list-style: none;
  padding: 0;
}

.accordion .innerpanel ul.innerpanel-links li a {
  display: block;
  padding: 2px 20px;
  color: var(--text-grey-500);
  font-size: 16px;
  line-height: 32px;
}

.accordion .innerpanel ul.innerpanel-links li a.active {
  color: var(--secondary-500);
}

.accordion .innerpanel ul.innerpanel-links li a:hover,
.accordion .innerpanel ul.innerpanel-links li a:active,
.accordion .innerpanel ul.innerpanel-links li a:focus {
  color: var(--secondary-500);
}

/* activate toggle */
.accordion .accordion_item.active .button {
  background-color: #ffffff;
  color: var(--purple-500);
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
}
.accordion .innerpanel_wrapper {
  height: 0;
  overflow: hidden;
  transition: height ease 0.2s;
}

.button {
  gap: 15px;
  position: relative;
  cursor: pointer !important;
}

.button:hover,
.button:active,
.button:focus {
  background: #fff;
  color: var(--purple-500);
  box-shadow: 0 2px 5px rgb(0 0 0 / 28%);
}

.button .dropdown {
  position: absolute;
  top: 40px;
  left: 0;
  padding: 5px 0px;
  background-color: #fff;
  display: none;
  width: 100%;
  z-index: 2;
  box-shadow: 0 2px 5px rgb(0 0 0 / 28%);
}

.button .dropdown.show {
  display: block;
}

.button .dropdown ul {
  margin-bottom: 0;
}

.button .dropdown ul li a {
  color: var(--purple-500);
  font-weight: 400;
  display: block;
  text-align: left;
  padding: 5px 15px;
}

.button .dropdown ul li a:hover,
.button .dropdown ul li a:focus,
.button .dropdown ul li a:active {
  background-color: var(--text-grey-50);
}

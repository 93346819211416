.formControl {
  display: block;
  width: 100%;
  height: calc(1em + 1.34rem + 2px);
  padding: 0.67rem 1rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #818181;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #333366;
  border-radius: 6px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.asLabel {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #3f3844;
}

.searchSection::after {
  width: 50%;
  margin-left: 25%;
  height: 1px;
  background-color: #339966;
}

.asSearchTitle {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #333366;
}

.labelP {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: "#A7A3A9";
}

.labelIcon {
  height: 18px;
  width: 18px;
}

.filterHeading {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  color: #333366;
}

.filterHeading::after {
  content: " ";
  height: 2px;
  width: 100px;
  display: block;
  background-color: #339966;
  position: absolute;
  bottom: -1px;
  left: 0;
}

.filterTitle {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 15px;
  color: #333366;
}

.zfilter .MuiPaper-root .MuiAccordionSummary-root {
  padding: 0px 0px !important;
}

.goTitle {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #333366;
}

.searchItemTitle {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #333366;
}

.searchItemType {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #656069;
}

.searchItemCityState {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
  color: #656069;
}

.searchItemBids {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #656069;
}

.searchItemBidsValue {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #243665;
}

.searchItemEndLabel {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #656069;
}

.bidlyItem {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 11px;
}

.sellerOtherAuction {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #656069;
}

.searchItemCityName {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
  text-decoration-line: underline;
  color: #339966;
}

.clockIcon {
  height: 13px;
  width: 13px;
}

.searhResultTitle {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
}

.paginationLabel {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  text-align: right;
  color: #333366;
}

@media (max-width: 767px) {
  .paginationLabel {
    text-align: center;
  }
}

.imagePanel {
  width: 100%;
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 11px 11px 0px 0px;
}

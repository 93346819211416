.main .smallTableContainer {
  background: var(--purple-50);
}

.main .tableContainer {
  padding: 10px 0px;
}

.main .smallTableContainer {
  padding: 10px;
}

.main .tableContainer .table th,
.main .tableContainer .table td {
  border: 0;
}

.main .tableContainer .table tr:first-child {
  border-top: 1px solid var(--purple-100);
}

.main .tableContainer .table tr {
  border-bottom: 1px solid var(--purple-100);
}

.main .tableContainer table tbody tr td {
  vertical-align: middle;
}

.main .tableContainer input[type="checkbox"],
.main .smallTableContainer input[type="checkbox"] {
  accent-color: var(--secondary-500);
}

.main .tableContainer table tbody tr td {
  font-size: 13px;
  font-weight: 700;
  padding-top: 20px;
  padding-bottom: 20px;
}

.main .tableContainer table tbody tr td .label,
.main .smallTableContainer .label {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: var(--text-grey-300);
  margin-bottom: 2px;
}

.main .tableContainer table tbody tr td .imgContainer {
  width: 130px;
  height: 100px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px;
  margin: auto;
}

.main .smallTableContainer .imgContainer {
  width: 100%;
  max-width: 230px;
  max-height: 150px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px;
  margin: auto;
}

.value + .label {
  margin-top: 10px !important;
}

.main .tableContainer table tbody tr td .value,
.main .smallTableContainer .value {
  font-size: 14px;
  font-weight: 700;
  color: var(--purple-500);
}

.main .tableContainer table tbody tr td .heading,
.main .smallTableContainer .heading {
  font-size: 15px;
  font-weight: 700;
  color: var(--purple-500);
  max-width: 250px;
}

.main .tableContainer table tbody tr td .heading a,
.main .smallTableContainer .heading a {
  color: var(--purple-500);
}

.main .tableContainer table tbody tr td .nrHeading,
.main .smallTableContainer .nrHeading {
  font-size: 15px;
  font-weight: 700;
  color: var(--purple-500);
}

.main .smallTableContainer .header {
  display: flex;
  align-items: center;
  padding: 5px 0 15px 0;
}

.main .smallTableContainer .panel .block {
  background: #fff;
  display: flex;
  align-items: center;
  padding: 15px 20px;
}

.main .smallTableContainer .panel .block.buttons {
  background: var(--purple-500);
}

.main .smallTableContainer .panel .blockPanel:not(:first-child) {
  border-top: 10px solid var(--purple-50);
  margin-top: 5px;
}

.main .smallTableContainer .panel .block .item:not(:first-child) {
  margin-top: 15px;
}

.main .smallTableContainer .panel .block .label {
  font-size: 13px;
  font-weight: 400;
  color: var(--text-grey-400);
}

.main .smallTableContainer .panel .block .value {
  font-size: 14px;
  font-weight: 700;
  color: var(--text-grey-400);
}

.main .smallTableContainer .panel .block .buttonRow {
  display: flex;
  justify-content: end;
  gap: 20px;
  flex: 1;
  align-items: center;
}

.main .smallTableContainer .panel .block .buttonRow .label,
.main .smallTableContainer .panel .block .buttonRow .value {
  color: #fff;
}

.main .smallTableContainer .panel .block .buttonRow .value .link {
  color: #fff;
}

.main .smallTableContainer .panel .block.bordered {
  border-top: 1px solid var(--purple-50);
}

.main .smallTableContainer .panel .block .totalRow {
  display: flex;
  justify-content: space-between;
  flex: 1;
  align-items: center;
}

.main .smallTableContainer .panel .block .totalRow .buttons {
  display: inline-flex;
  gap: 10px;
  padding: 0px 10px;
}

.main .smallTableContainer .panel .block .totalRow .buttons a {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.main .smallTableContainer .panel .block .totalRow .buttons a img {
  max-width: 15px;
  max-height: 25px;
}

.main .smallTableContainer {
  display: none;
}

@media (max-width: 1199px) {
  .main .tableContainer {
    display: none;
  }

  .main .smallTableContainer {
    display: block;
  }
}

.badge {
  font-size: 1rem;
}

@media (max-width: 767px) {
  .buttonsAlignment{
    text-align: right;
  }
}

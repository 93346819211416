.buttonsPanel {
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 15px;
}

@media (max-width: 420px) {
  .buttonsPanel {
    flex-direction: column;
  }
  .buttonsPanel .item {
    width: 100%;
  }
}

.checkoutButtonsPanel {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.checkoutButtonsPanel a:last-child {
  justify-self: flex-end;
  margin-left: auto;
}

@media (max-width: 560px) {
  .checkoutButtonsPanel {
    gap: 10px;
    flex-direction: column;
  }

  .checkoutButtonsPanel a {
    width: 100%;
  }
}

ul.instructionsList {
  list-style: disc;
  padding-left: 25px;
  line-height: 26px;
  font-size: 16px;
}

ul.instructionsList li {
  margin-bottom: 14px;
  padding-left: 25px;
}

p.instructions {
  line-height: 26px;
  font-size: 16px;
}

.bankInfo .bankInfoRow {
  display: flex;
  margin-bottom: 20px;
}

.bankInfo .bankInfoRow .label,
.bankInfo .bankInfoRow .value {
  font-size: 18px;
  line-height: 22px;
}

.bankInfo .bankInfoRow .label {
  width: 30%;
  color: var(--text-grey-400);
}

.bankInfo .bankInfoRow .value {
  width: 65%;
  margin-left: 5%;
  color: var(--purple-500);
}

@media (max-width: 767px) {
  .bankInfo .bankInfoRow {
    flex-direction: column;
    gap: 5px;
  }

  .bankInfo .bankInfoRow .label {
    width: 100%;
    font-size: 14px;
  }

  .bankInfo .bankInfoRow .value {
    width: 100%;
    margin-left: 0;
  }
}

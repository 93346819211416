.container {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.container .desc {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: var(--purple-500);
  text-align: center;
  margin-top: 20px;
}

.container .heading {
  border-width: 1px;
  padding-bottom: 20px;
}

.container .heading::after {
  content: none !important;
}

@media (max-width: 575px) {
  .container .heading {
    font-size: 24px !important;
  }

  .container .desc {
    font-size: 16px;
  }
}

.detailedLinkDashboardCard {
  background: #ffffff;
  border-radius: 6px;
  padding: 20px 20px 0px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dashboardCard {
  background: #ffffff;
  border-radius: 6px;
  padding: 20px 20px;
}

.detailedLinkDashboardCard .dashboardCard {
  background-color: none;
  border-radius: none;
  padding: 0;
}

.dashboardCardHeading {
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 20px;
}

.doubleHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.listingInfoBlocks {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.listingInfoBlock {
  background: #dbebfa;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--purple-500);
  padding: 15px 15px;
}

.listingInfoBlock .label {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
}

.listingInfoBlock .value {
  border-left: 1px solid #fff;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
}

.countsDiv {
  display: flex;
  flex-direction: column;
  gap: 25px;
  text-align: center;
  justify-content: center;
}

.countsDiv .value {
  font-weight: 700;
  font-size: 32px;
  line-height: 37px;
  text-align: center;
  color: var(--purple-500);
}

.countsDiv .label {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: var(--text-grey-300);
}

.detailLink {
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: var(--secondary-300);
  padding: 20px;
  border-top: 1px solid var(--secondary-50);
  cursor: pointer;
}

.detailLink:hover,
.detailLink:active,
.detailLink:focus {
  color: var(--purple-500);
}

.listCard .listItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--secondary-50);
  padding: 20px 0px;
}

.listCard .listItem:last-of-type {
  border: none;
}

.listCard .listItem .label {
  font-weight: 700;
  font-size: 0.8rem;
  line-height: 18px;
  color: var(--text-grey-300);
}

.listCard .listItem .value {
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: var(--purple-500);
}

.modal {
  position: fixed;
  top: 35vh;
  left: 10%;
  width: 80%;
  z-index: 10001;
  overflow: hidden;
  background: none !important;
  box-shadow: none !important;
}

.content {
  padding: 1rem;
}

.backdroploading {
  background: white !important;
  opacity: 0.9 !important;
  z-index: 10001 !important;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

@media (min-width: 768px) {
  .modal {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}

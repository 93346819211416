/* Position and sizing of burger button */
.bm-burger-button {
  display: none;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
  display: none;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: white;
  font-size: 14px;
  line-height: 16px;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  z-index: 1002 !important;
}

/* Individual item */
.bm-item {
  display: inline-block;
  color: #d1d1d1;
  margin-bottom: 10px;
  text-align: left;
  text-decoration: none;
  transition: color 0.2s;
}

.bm-item:hover {
  color: #ffffff;
}

ul.sidebar-nav-profile {
  margin: 0;
}

ul.sidebar-nav-profile li a {
  padding: 15px 20px;
  display: flex;
  color: var(--purple-500);
  align-items: center;
  column-gap: 10px;
  font-weight: 700;
  background-color: var(--secondary-100);
}

ul.sidebar-nav-back {
  margin: 0;
}

ul.sidebar-nav-back li a {
  padding: 15px 20px;
  display: flex;
  color: var(--purple-500);
  align-items: center;
  column-gap: 10px;
  font-weight: 700;
  background-color: var(--secondary-100);
}

.sidebar-nav-back a.link-toggle::before {
  content: "e";
  transform: rotate(180deg);
  font: normal normal normal 14px/1 font-electro;
  font-size: inherit;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-size: 1.00012rem;
  color: var(--text-grey-400);
}

ul.sidebar-nav-back + ul.sidebar-nav,
ul.sidebar-nav-profile + ul.sidebar-nav {
  overflow-y: auto;
  max-height: 90vh;
}

ul.sidebar-nav li {
  border-bottom: 1px solid var(--purple-50);
}

ul.sidebar-nav li a {
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  color: var(--purple-500);
  align-items: center;
}

.sidebar-nav a.link-toggle::after {
  content: "e";
  font: normal normal normal 14px/1 font-electro;
  font-size: inherit;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-size: 1.00012rem;
  color: var(--text-grey-400);
  margin-top: 0.25025rem;
}

.sidebar-nav,
.sidebar-nav-back,
.sidebar-nav-profile {
  list-style: none;
  padding: 0;
}

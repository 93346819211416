.google-btn {
  box-sizing: border-box;
  box-shadow: none !important;
  height: 44px;
  left: 739px;
  top: 606px;
  background: #ffffff;
  border: 1px solid #3f3844 !important;
  border-radius: 6px !important;
  margin-top: 1px;

  font-family: "Helvetica" !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 13px !important;
  line-height: 16px;
  color: #3f3844 !important;
}

.br-none {
  border-right: none;
}

.br-left {
  border-left: 2px solid #e4e5e9;
}

.title-1 {
  height: 28px;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #333366;
}

.title-2 {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #333366;
}

.pg {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 24px;
  color: #5c5e63;
}

.pg-1 {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 24px;
  color: #5c5e63;
}

.pg-2 {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 18px !important;
  line-height: 30px;
  color: #3f3844;
}

.pg-3 {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 24px !important;
  line-height: 32px;
  color: var(--purple-500);
}

.btn-bordered {
  /* login */
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 17px 44px;
  font-weight: 500;
  text-transform: uppercase;

  width: 200px;
  height: 50px;

  /* white */

  background: #ffffff;
  /* Secondary Color */

  border: 1px solid var(--secondary-500);
  border-radius: 100px;
}

.text-decoration-underline {
  text-decoration: underline;
}

.text-transform-upper {
  text-transform: uppercase;
}

.form-control-psw {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.34rem + 2px);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #818181;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #339966;
  border-radius: 6px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control-psw:focus {
  box-shadow: none;
  border-color: #66afe9;
}

.psw-field {
  height: 40px;
  margin: 1px;
  width: 100%;
  padding: 0.67rem 1rem;
  border: none;
}

.border-green {
  background: #ffffff;
  border: 1px solid #339966;
  box-shadow: 0px 5px 10px rgba(36, 54, 101, 0.15);
  border-radius: 12px;
}

.card-custom{
  height: 432px;
}

.card-custom-footer {
  border-radius: 12px !important;
  border-top: 0px;
}

.border-purpel {
  background: #ffffff;
  border: 1px solid #333366;
  box-shadow: 0px 5px 10px rgba(36, 54, 101, 0.15);
  border-radius: 12px;
}

.at-ul {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 23px;
  color: #5c5e63;
}

.at-ul > li::marker {
  color: #339966;
  font-size: 1.4rem;
}

.color-2 {
  color: #333366;
}

.cursor-pointer {
  cursor: pointer;
}

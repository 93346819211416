.imgContainer {
  width: 200px;
  height: 140px;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  border-radius: 12px;
  margin: 0px 15px;
}

.label {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: var(--text-grey-300);
  margin-bottom: 2px;
}

.value {
  font-size: 14px;
  font-weight: 700;
  color: var(--purple-500);
}

@media (max-width: 991px) {
  .topRow {
    flex-direction: column;
  }
}
